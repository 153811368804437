import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faTwitter, faLinkedinIn, faYoutube, faInstagram, faTiktok } from '@fortawesome/free-brands-svg-icons';
import { Link } from 'react-router-dom';

const Navbar = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])


  const toggleMenu = () => {
    setMenuOpen(!menuOpen);

  };

  return (
    <div className={`sm:px-20 p-4 transition-colors duration-300 ${menuOpen ? 'bg-white' : 'bg-transparent'}`}>
      <div className='flex justify-between items-center w-full'>
        <div className="">
          <Link to='/'>

            <img className="sm:h-20 sm:w-20 w-10 h-10" src="/Inyxia.png" alt="logo" />
          </Link>
        </div>

        {/* Menu Button */}
        <div className={` sm:text-2xl text-xl font-medium cursor-pointer ${menuOpen ? 'text-black' : 'text-white'}`} onClick={toggleMenu}>
          {menuOpen ? '- Menu ' : '+ Menu'}
        </div>


        <div className={`flex items-center space-x-4 ${menuOpen ? 'text-black' : 'text-white'}`}>
          <a href="https://www.youtube.com/@InyxiaSchoolofThought" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faYoutube} className="h-4" />
          </a>
          <a href="https://www.instagram.com/inyxiaschoolofthought/" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faInstagram} className="h-4" />
          </a>
          <a href="https://www.facebook.com/inyxiaschoolofthought/" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faFacebookF} className="h-4" />
          </a>
          <a href="https://x.com/inyxiaSOT" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faTwitter} className="h-4" />
          </a>
          <a href="https://www.linkedin.com/company/inyxiaschoolofthought" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faLinkedinIn} className="h-4" />
          </a>
          <a href="https://www.tiktok.com/@inyxiaschoolofthought" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faTiktok} className="h-4" />
          </a>
        </div>
      </div>

      {/* Dropdown Menu */}
      {menuOpen && (
        <div className="absolute sm:top-28  left-0 w-full bg-white   p-4">
          <div>
            <div className='grid sm:grid-cols-3 grid-cols-2 cursor-pointer'>
              <div className='sm:ml-4'>
                <ul className='cursor-pointer' style={{ color: '#5789a9' }}>
                  <li className='text-xl'>Philosophy</li>
                  <hr class="h-px  mr-20 my-2 bg-black border-0 dark:bg-gray-700" />
                  <Link to='/dual-nature-of-learning#ab'>
                    <li className=''>Absorptive</li>
                  </Link>
                  <Link to='/dual-nature-of-learning#ex'>
                    <li className='my-2 '>Explorative</li>
                  </Link>
                  {/* <li className=''>Toap run</li> */}
                </ul>
              </div>

              <div>
                <ul className='cursor-pointer' style={{ color: '#5789a9' }}>
                  <li className=' text-xl'>Technology</li>
                  <hr class="h-px mr-20 my-2 bg-black border-0 dark:bg-gray-700" />
                  <Link to='/advance-technology#ai'>

                    <li className=''>Artificial Intelligence</li>
                  </Link>

                  <Link to='/advance-technology#ar'>
                    <li className='my-2 '>Augmented Reality</li>

                  </Link>

                  <Link to='/advance-technology#vr' >
                    <li className='my-2 '>Virtual Reality</li>

                  </Link>


                </ul>
              </div>

              {/* <div>
                <ul className='cursor-pointer' style={{ color: '#5789a9' }}>
                  <li className=' text-xl'>Monitor</li>
                  <hr class="h-px mr-20 my-2 bg-black border-0 dark:bg-gray-700" />
                  <li className=''>Physilog</li>
                  <li className='my-2 '>Brain Vitals</li>
                  
                </ul>
              </div> */}

              <div>
                <ul className='cursor-pointer ' style={{ color: '#5789a9' }}>
                  <li className=' text-xl '>Info</li>
                  <hr class="h-px mr-20 my-2 bg-black border-0 dark:bg-gray-700" />
                  {/* <li className='text-black'>MindMaze</li> */}
                  <Link to='/about'>
                    <li className='cursor-pointer '>About Us</li>

                  </Link>
                  <Link to='/contact'>
                    <li className='my-2 '>Contact</li>
                  </Link>
                  {/* <li className='my-2 text-black'>Healthcare</li> */}
                  {/* <Link to='/clinical-pipline'>
                    <li className='text-black'>Clinical Pipeline</li>
                  </Link> */}
                </ul>
              </div>

              {/* <div>
                <ul className='cursor-pointer' style={{ color: '#5789a9' }}>
                  <li className=' text-xl text-black'> Media</li>
                
                  <li className='text-black mt-2'>Testimonials</li>
                  <Link to='/careers'>
                  <li className='my-2 text-black'>Careers</li>
                  </Link>
               
                </ul>
              </div> */}

            </div>

          </div>



        </div>
      )}
    </div>
  );
};

export default Navbar;
